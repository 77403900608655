import React from 'react'
import { Redirect, withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Container, Box, Grid, Typography, Button, TextField } from '@material-ui/core'

import api from '../../lib/api'
import { LoginMode, AuthResponse } from '../../lib/types'

interface IProps extends RouteComponentProps { 
  login: string
  mode: LoginMode
  onValidated: (authResponse: AuthResponse) => void
}

interface IState { 
  pass: string
}

class PassForm extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    
    this.state = { 
      pass: "",
    }
  }
  
  render() {
    if (this.props.login.length < 1) {
      return <Redirect to='.' />
    }
    
    return (
      <Container>
        <Box mt={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                Введите пароль для: 
              </Typography>
              <Typography component="h1" variant="h5" color='textPrimary'>
                {this.props.login}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField label='Пароль' type='password' autoFocus variant='outlined' value={this.state.pass} onChange={this.handlePassChange.bind(this)} />
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleSubmit.bind(this)}>Войти</Button>
            </Grid>
            <Grid item xs={12}>
              <Link to='./code'>Забыли пароль?</Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
  
  handlePassChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({pass: e.target.value})
  }
  
  handleGoBack() {
    this.props.history.goBack()
  }
  
  handleSubmit() {
    if (this.state.pass.length < 1) {
      toast.error('Пожалуйста, введите пароль')
      return
    }
      
    api.authLogin(this.props.login, this.state.pass).then(
      (result) => {
        this.props.onValidated(result)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(PassForm)
