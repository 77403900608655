import { AccountKind, MapPoint, BranchKind } from './types'

export interface AuthInfo {
    id: string
    login: string
    isAnonymous: boolean
}


export interface Order {
    id: string
    deviceId: string
    accountId: string
    rideId: string
    priceRateId: number
    expirationTime: number
    isSandbox: boolean
}
export function isOrder(o: object): boolean {
    return o
        && 'id' in o
        && 'deviceId' in o
        && 'accountId' in o
        && 'priceRateId' in o
        && 'expirationTime' in o
        && 'isSandbox' in o
}


export interface DeviceInfo {
    battery: number
}


export interface ControlInfo {
    canControl: boolean,
    isDeviceOn: boolean,
    rideExpTime: number,
    lockExpTime: number
}
export function isControlInfo(o: object): boolean {
    return o 
        && 'canControl' in o
        && 'isDeviceOn' in o
        && 'rideExpTime' in o
        && 'lockExpTime' in o
}

export interface Account {
  id: string
  login: string
  isRegistered: boolean
  kind: AccountKind
}


export interface Payment {
  id: number,
  amount: number,
  date: string,
  isSandbox: boolean,
  cardLastFour: string,
  receiptId?: string,
}


export interface Card {
    id: string,
    lastFour: string,
    isSandbox: Boolean,
}


export interface MapCache {
  id: string,
  points: MapPoint[]
}


export interface Branch {
  id: number,
  title: string,
  warningText?: string,
  purchaseText?: string,
  kind: BranchKind,
  orderText: string,
}
