
let config = {
    orderCurrency: 'RUB',
    
    authTokenRefreshTimeBefore: 10 * 60,
    
    apiServerEnv: process.env.API_SERVER_ENV ?? 'staging',
    adminUrl: process.env.ADMIN_URL ?? 'https://admin.tenzio.ru/',
    ymapsKey: process.env.YMAPS_KEY ?? '57d4451b-74c1-41aa-9a3a-d83ac07ead2f',
    
    receiptUrl: process.env.RECEIPT_URL ?? 'https://receipts.ru/%id%',
}

export default config
