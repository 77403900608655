import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { Payment } from '../lib/entities'
import config from '../lib/config'
import api from '../lib/api'

interface IProps {
  rideId: string
}

interface IState {
  receiptIds: string[],
}

class OrderReceipts extends React.Component<IProps, IState> {
  reloadTimer?: NodeJS.Timeout = undefined
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      receiptIds: []
    }
  }
  
  componentDidMount() {
    this.reloadReceipts()
    this.restartReloadTimer()
  }
  
  componentWillUnmount() {
    if (this.reloadTimer) {
      clearInterval(this.reloadTimer)
      this.reloadTimer = undefined
    }
  }
  
  render() {
    if (this.state.receiptIds.length < 1) {
      return ''
    }
    
    return (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6">
            { this.state.receiptIds.length > 1 ? "Чеки" : "Чек" }
          </Typography>
        </Grid>
        { this.state.receiptIds.map(receiptId => this.renderReceipt(receiptId)) }
      </Grid>
    )
  }
  
  renderReceipt(receiptId: string) {
    let url = config.receiptUrl.replace('%id%', receiptId)
    return (
      <Grid item xs={12}>
        <a key={`receipt_${receiptId}`} href={url} target='_blank' rel='noopener noreferrer'>{url}</a>
      </Grid>
    )
  }
  
  reloadReceipts() {
    api.paymentsGetForRide(this.props.rideId).then(
      (response) => {
        let payments: Payment[] = response
        let receiptIds: string[] = payments.flatMap(payment => payment.receiptId ? [payment.receiptId] : [])
        this.setState({ receiptIds })
        this.restartReloadTimer()
      },
      (_error) => { }
    )
  }
  
  restartReloadTimer() {
    if (this.reloadTimer) {
      clearInterval(this.reloadTimer)
    }
    
    let interval: number = this.state.receiptIds.length > 0 ? 15 : 3
    this.reloadTimer = setInterval(() => this.reloadReceipts(), interval * 1000)
  }
}

export default OrderReceipts
