import React from 'react'
import { Link, withRouter, RouteComponentProps, Switch, Route } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'

import SetPass from '../components/account/set-pass'
import { Account } from '../lib/entities'


interface IProps extends RouteComponentProps { 
  account: Account
}

interface IState { }

class AccountScreen extends React.Component<IProps, IState> {
  render() {
    let path = this.props.match.path
    let url = this.props.match.url
    return (
      <Box mt={2}>
        <Switch>
          <Route path={`${path}/pass`}>
            <SetPass account={this.props.account} onPassChange={this.handlePassChange.bind(this)} />
          </Route>
          <Route path={`${path}/`}>
            <Typography variant='h6'>Аккаунт</Typography>
            <Box mt={2}>
              <Button component={Link} to={`${url}/pass`} variant='contained' color='primary'>Сменить пароль</Button>
            </Box>
          </Route>
        </Switch>
      </Box>
    )
  }
  
  handlePassChange() {
    this.props.history.push('/')
    
    toast.success('Пароль изменен')
  }
}


export default withRouter(AccountScreen)
