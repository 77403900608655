

export function tkAddWidgetScript() {
  let script = document.createElement('script')
  script.setAttribute('src', 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js')
  document.body.appendChild(script)
}

// 5536913805792674
// 05/24 260

export function tkPay(orderId, userId, amount, currency, description, onSuccess, onFail, onComplete) {
  let form = document.createElement('form')
  form.name = 'TinkoffPayForm'
  
  let receipt = JSON.stringify({
      "Email": "sct@x2.team",
      "Name": description ?? "Оплата",
      // "Phone": phone,
      "EmailCompany": "sct@x2.team",
      "Taxation": "usn_income_outcome",
      "Items": [
        {
          "Name": description ?? 'Покатушки на самокате',
          "Price": amount + '00',
          "Quantity": 1.00,
          "Amount": amount + '00',
          "PaymentMethod": "full_prepayment",
          "PaymentObject": "service",
          "Tax": "none"
        }
      ]
  });
  
  let fields = {
    terminalkey: '1656360804591',
    frame: false,
    language: 'ru',
    amount: amount,
    order: orderId,
    description: description,
    email: 'sct@x2.team',
    receipt,
  }
  
  for (let field in fields) {
    let input = document.createElement('input')
    input.type = 'text'
    input.name = field
    input.value = fields[field]
    form.appendChild(input)
  }
  
  let input = document.createElement('input')
  input.type = 'submit'
  input.textContent = 'submit'
  form.appendChild(input)
  
  window.pay(form)
}
