import React from 'react'
import { Redirect, withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Box, Grid, Typography, Button, TextField } from '@material-ui/core'
import { toast } from 'react-toastify'

import api from '../../lib/api'
import { LoginMode, AuthResponse } from '../../lib/types'

interface IProps extends RouteComponentProps { 
  login: string
  mode: LoginMode
  onValidated: (authResponse: AuthResponse) => void
}

interface IState { 
  code: string
  wasCodeRequested: boolean
  canResend: boolean
  resendDate: Date
}

class CodeForm extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    
    this.state = { 
      code: "", 
      wasCodeRequested: false,
      canResend: false,
      resendDate: new Date(),
    }
  }
  
  render() {
    if (this.props.login.length < 1) {
      return <Redirect to='.' />
    }
    
    if (!this.state.wasCodeRequested) {
      return this.renderFirstTimeForm()
    }
    
    return (
      <Container>
        <Box mt={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                Введите код, который мы отправили на: 
              </Typography>
              <Typography component="h1" variant="h5" color='textPrimary'>
                {this.props.login}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField label='Код' autoFocus variant='outlined' inputProps={{ inputMode: 'numeric' }} value={this.state.code} onChange={this.handleCodeChange.bind(this)} />
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleSubmit.bind(this)}>Отправить</Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
  
  renderFirstTimeForm() {
    return (
      <Container>
        <Box mt={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                Подтверждение {this.props.mode === LoginMode.phone ? "номера телефона" : "почты"}: 
              </Typography>
              <Typography component="h1" variant="h5" color='textPrimary'>
                {this.props.login}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleRequestCode.bind(this)}>Отправить код</Button>
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleGoBack.bind(this)}>
                Сменить {this.props.mode === LoginMode.phone ? "номер" : "почту"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
  
  handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({code: e.target.value})
  }
  
  handleGoBack() {
    this.props.history.push('./')
  }
  
  handleRequestCode() {
    this.setState({
      wasCodeRequested: true,
      canResend: false,
      resendDate: new Date(new Date().getTime() + 60 * 1000),
    })
    
    let request = this.props.mode === LoginMode.phone ? api.authRequestCodeForPhone : api.authRequestCodeForEmail
    request.bind(api)(this.props.login).then(
      (_result) => {
        this.setState({
          wasCodeRequested: true,
          canResend: false,
          resendDate: new Date(new Date().getTime() + 60 * 1000),
        })
      },
      (error) => {
        this.setState({
          wasCodeRequested: false
        })
        toast.error(error.message)
      }
    )
  }
  
  handleSubmit() {
    //FIXME: validate code
    
    let request = this.props.mode === LoginMode.phone ? api.authConfirmPhoneCode : api.authConfirmEmailCode
    request.bind(api)(this.props.login, this.state.code).then(
      (result) => {
        this.props.onValidated(result)
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

//FIXME: add resend timer

export default withRouter(CodeForm)
