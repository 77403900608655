import React from 'react'
import { NavLink } from 'react-router-dom'
import { SwipeableDrawer, List, ListItem, ListItemText } from '@material-ui/core'

import { Account } from '../lib/entities'
import { AccountKind } from '../lib/types'

interface IProps { 
  account: Account
}

interface IState { 
  isOpened: boolean
}

class SideMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isOpened: false
    }
  }
  
  render() {
    let isOnIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    return (
      <SwipeableDrawer 
        anchor='left' 
        open={this.state.isOpened} 
        onOpen={()=>this.setState({ isOpened: true })} 
        onClose={()=>this.setState({ isOpened: false })} 
        disableBackdropTransition={!isOnIOS} 
        disableDiscovery={isOnIOS}
      >
      {
        this.props.account.kind === AccountKind.anonymous
          ? this.renderAnonymousMenu()
          : this.renderRegisteredMenu()
      }
      </SwipeableDrawer>
    )
  }
  
  renderAnonymousMenu() {
    return (
      <List>
        <ListItem button component={NavLink} to='/' exact activeClassName="Mui-selected" onClick={this.close.bind(this)}>
          <ListItemText primary='Главная' />
        </ListItem>
      </List>
    )
  }
  
  renderRegisteredMenu() {
    return (
      <List>
        <ListItem button component={NavLink} to='/' exact activeClassName="Mui-selected" onClick={this.close.bind(this)}>
          <ListItemText primary='Главная' />
        </ListItem>
        
        <ListItem divider />
        
        <ListItem button component={NavLink} to='/account' activeClassName="Mui-selected" onClick={this.close.bind(this)}>
          <ListItemText primary='Аккаунт' />
        </ListItem>
        <ListItem button component={NavLink} to='/cards' activeClassName="Mui-selected" onClick={this.close.bind(this)}>
          <ListItemText primary='Карты' />
        </ListItem>
        <ListItem button component={NavLink} to='/payments' activeClassName="Mui-selected" onClick={this.close.bind(this)}>
          <ListItemText primary='Платежи' />
        </ListItem>
        
        <ListItem divider />
        
        <ListItem button component={NavLink} to='/logout' activeClassName="Mui-selected" onClick={this.close.bind(this)}>
          <ListItemText primary='Выйти' />
        </ListItem>
      </List>
    )
  }
  
  open() {
    this.setState({ isOpened: true })
  }
  
  close() {
    this.setState({ isOpened: false })
  }
}


export default SideMenu
