import React from 'react'
import { Box, Typography, List, ListItem, ListItemText, Button } from '@material-ui/core'
import dateformat from 'dateformat'

import { Payment } from '../lib/entities'
import api from '../lib/api'

interface IProps { }
interface IState { 
  payments: Payment[]
  isLoaded: boolean
  error: Error | null
  page: number
  hasMore: boolean
}

class PaymentsScreen extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      payments: [],
      error: null,
      page: 1,
      hasMore: false,
    }
  }
  
  componentDidMount() {
    this.loadPayments(1)
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (
        <Box mt={2}>
          <Typography variant='subtitle1'>Загружаем..</Typography>
        </Box>
      )
    }

    if (this.state.error) {
      return (
        <Box mt={2}>
          <Typography variant='subtitle1'>Ошибка {this.state.error.message}</Typography>
        </Box>    
      )
    }
    
    if (this.state.payments.length < 1) {
      return (
        <Box mt={2}>
          <Typography variant='h6'>Пока не было платежей</Typography>
          <Typography variant='subtitle1'>Покатайтесь на самокате, чтобы появилась история оплат</Typography>
        </Box>
      )
    }
    
    return (
      <Box mt={2}>
        <Typography variant='h6'>Последние платежи</Typography>
        <List>
          {this.state.payments.map((payment) => this.renderPayment(payment))}
        </List>
        {this.renderMoreButtonIfNeeded()}
      </Box>
    )
  }
  
  renderPayment(payment: Payment) {
    let date = new Date(payment.date)
    let dateString = dateformat(date, 'dd/mm/yyyy HH:MM')
    
    return (
      <ListItem>
        <ListItemText>{dateString} - {payment.amount}р с карты *{payment.cardLastFour}</ListItemText>
      </ListItem>
    )
  }
  
  renderMoreButtonIfNeeded() {
    if (!this.state.hasMore) {
      return (
        <Box mt={2}>
          <Typography>Это все платежи</Typography>
        </Box>
      )
    }
    
    return (
      <Box mt={2}>
        <Button variant='contained' color='primary' onClick={this.handleLoadMore.bind(this)}>Загрузить еще</Button>
      </Box>
    )
  }
  
  loadPayments(page: number) {
    api.paymentsGet(page).then(
      (response) => {
        let payments: Payment[] = response
        this.setState((state) => { 
          return {
            isLoaded: true,
            error: null,
            payments: page === 1 ? payments : state.payments.concat(payments),
            page: page,
            hasMore: payments.length > 0,
          }
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
  
  handleLoadMore() {
    this.loadPayments(this.state.page + 1)
  }
}

export default PaymentsScreen
