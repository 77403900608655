import React from 'react'
import { toast } from 'react-toastify'
import { Grid, Typography, Button, Paper, Box } from '@material-ui/core'

import OrderReceipts from './order-receipts'

import { addWidgetScript, pay } from '../lib/payments'
import { Account, Order, isOrder, Card, Branch } from '../lib/entities'
import { PriceRate } from '../lib/types'
import api from '../lib/api'
import config from '../lib/config'

interface IProps {
  deviceId: string
  branch: Branch
  account: Account
  title?: string
  isExpanded: boolean
  batteryCharge: number
  onPurchase: () => void // FIXME: check this one. Was called on recurrent payment only. Changed to both recurrent and first payments.
}

interface IState { 
  cards: Card[]
  priceRates: PriceRate[]
  selectedPriceRate: PriceRate | null
  isExpanded: boolean
  isShowingCards: boolean
  isLoading: boolean
  error: Error | null
  rideId?: string
}

class OrderControl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isExpanded: props.isExpanded,
      cards: [],
      priceRates: [],
      selectedPriceRate: null,
      isShowingCards: false,
      isLoading: false,
      error: null,
    }
  }
  
  componentDidMount() {
    addWidgetScript()
    this.reloadRates()
    //this.reloadCards() //don't use stored cards for now
  }
  
  render() {
    if (this.state.isLoading) {
      return (<Typography>Загружаем..</Typography>)    
    }
    
    if (this.state.error) {
      return (<Typography>Ошибка {this.state.error.message}</Typography>)    
    }
    
    if (!this.state.isExpanded) {
      return (
        <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleExpandClick.bind(this)}>
          {this.props.title ?? " "}
        </Button>
      )
    }
    
    let hideExtendText: boolean = false 
    if (!this.state.priceRates.find((rate) => rate.minutes > 0)) {
      hideExtendText = true
    }
    
    if (!this.state.selectedPriceRate) {
      return (
        <Paper variant='outlined'><Box p={2}>
          <Grid container spacing={2}>
            {
              this.state.priceRates.length < 1 
                ? this.renderNoPrices() 
                : this.state.priceRates.map((rate) => this.renderPriceButton(rate))
            }
            <Grid item xs={12} hidden={hideExtendText}>
              <Typography component="h1" variant="subtitle1">
                0р за старт поездки! Докупить тариф и продлить поездку можно в любое время любым тарифом
              </Typography>
            </Grid>
            { this.renderReceiptsIfNeeded() }
          </Grid>
        </Box></Paper>
      )
    }
    
    if (!this.state.isShowingCards) {
      <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleNewCardClick.bind(this)}>
        {this.props.title ?? " "}
      </Button>
    }
    
    let priceRate = this.state.selectedPriceRate
    return (
      <Paper variant='outlined'><Box p={2}>
        <Grid container spacing={2}>
          { 
            this.props.title 
            ? <Grid item xs={12}><Typography component="h1" variant="h5">{this.props.title}</Typography></Grid> 
            : '' 
          }
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              { priceRate.minutes > 0 ? priceRate.minutes + ' минут за ' : '' }
              {priceRate.price}р
            </Typography>
          </Grid>
          {this.state.cards.map((card) => this.renderCardButton(card))}
          <Grid item xs={12}>
            <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleNewCardClick.bind(this)}>
              {this.state.cards.length > 0 ? 'Другой картой' : 'Оплатить'}
            </Button>
          </Grid>
          { 
            this.state.priceRates.length > 1 
            ? <Grid item xs={12}>
                <Button type='submit' size='large' variant='contained' onClick={this.handleCancelClick.bind(this)}>Отменить</Button>
              </Grid>
            : this.renderReceiptsIfNeeded()
          }
        </Grid>
      </Box></Paper>
    )
  }
  
  renderPriceButton(priceRate: PriceRate) {
    if (priceRate.minutes < 1) {
      return (
        <Grid item xs={12} key={`price_item_${priceRate.id}`}>
          <Button type='submit' value={priceRate.id} size='large' variant='contained' color='primary' onClick={this.handlePriceRateClick.bind(this)}>
            <span className='price-button-text'>
              {priceRate.price}р
            </span>
          </Button>
        </Grid>
      )
    }
    
    let minutePrice = Math.floor(100 * priceRate.price / priceRate.minutes) / 100
    let lowCharge = this.props.batteryCharge < priceRate.minBattery
    return (
      <Grid item xs={12} key={`price_item_${priceRate.id}`}>
        <Button type='submit' value={priceRate.id} size='large' variant='contained' color='primary' onClick={this.handlePriceRateClick.bind(this)} disabled={lowCharge}>
          <span className='price-button-text'>
            {priceRate.minutes} МИНУТ ЗА {priceRate.price}р
            <br />
            { lowCharge ? 'низкий заряд' : `(${minutePrice}р/мин)` }
          </span>
        </Button>
      </Grid>
    )
  }
  
  renderNoPrices() {
    return (
      <Grid item xs={12}>
        <Typography component="h1" variant="h5">Нет доступных тарифов</Typography>
      </Grid>
    )
  }
  
  renderCardButton(card: Card) {
    return (
      <Grid item xs={12}>
        <Button type='submit' value={card.id} size='large' variant='contained' color='primary' onClick={this.handleSavedCardClick.bind(this)}>
          Использовать *{card.lastFour}{card.isSandbox ? " (тестово)" : ""}
        </Button>
      </Grid>
    )
  }
  
  renderReceiptsIfNeeded() {
    if (!this.state.rideId) {
      return ''
    }
    
    return <OrderReceipts rideId={this.state.rideId} />
  }
  
  
  handleExpandClick() {
    this.setState({ isExpanded: true })
  }
  
  handleOrderClick() {
    if (this.state.cards.length < 1) {
      this.handleNewCardClick()
      return
    }
    
    this.setState({
      isShowingCards: true
    })
  }
  
  handlePriceRateClick(e: React.MouseEvent<HTMLButtonElement>) {
    let rateId = +e.currentTarget.value
    let rate = this.state.priceRates.find((rate) => rate.id === rateId)
    if (!rate) {
      toast.error('No price rate found')
      return
    }
    
    this.setState({
      selectedPriceRate: rate
    })
  }
  
  handleCancelClick(){ 
    this.setState({
      isShowingCards: false,
      selectedPriceRate: null,
    })
  }
  
  handleNewCardClick() {
    let accountId = this.props.account.id
    if (!this.state.selectedPriceRate) {
      return
    }
    
    let priceRate = this.state.selectedPriceRate
    
    api.orderPrepare(this.props.deviceId, priceRate?.id ?? 0).then(
      (response) => {
        if (!isOrder(response)) {
          toast.error('Malformed response')
          return
        }
        
        let order: Order = response
        pay({
          orderId: order.id,
          accountId: accountId,
          amount: priceRate.price,
          currency: config.orderCurrency,
          description: this.props.branch.orderText,
          onSuccess: (_options) => {
            console.log("pay - success")
            this.setState({
              isShowingCards: false,
              selectedPriceRate: null,
              rideId: order.rideId,
            })
            
            this.props.onPurchase()
          },
          onFail: (reason, _options) => {
            console.log("pay - fail - " + reason)
            
            api.orderCancel(order.id)
            this.setState({
              isShowingCards: false
            })
          },
          onComplete: (_options) => {
            console.log("pay - complete")
          }
        })
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  handleSavedCardClick(e: React.MouseEvent<HTMLButtonElement>) {
    let cardId = e.currentTarget.value
    this.setState({
      isLoading: true
    })
    
    let priceRate = this.state.selectedPriceRate
    api.orderRecard(this.props.deviceId, cardId, priceRate?.id ?? 0).then(
      (response) => {
        let order: Order = response
        
        setTimeout(() => {
          this.setState({
            isLoading: false,
            rideId: order.rideId,
          })
          
          this.props.onPurchase()
        }, 5000)
      },
      (error) => {
        toast.error(error.message)
        this.setState({
          isLoading: false
        })
      }
    )
  }
  
  reloadCards() {
    api.cardsGet().then(
      (response) => {
        this.setState({
          cards: response,
        })
      },
      (_error) => {
        //TODO: 
      }
    )
  }
  
  reloadRates() {
    this.setState({
      error: null,
      isLoading: true,
    })
    
    api.orderGetPricesForDevice(this.props.deviceId).then(
      (response) => {
        let priceRates: PriceRate[] = response
        this.setState(state => { 
          return {
            isLoading: false,
            selectedPriceRate: priceRates.length === 1 ? priceRates[0] : state.selectedPriceRate,
            priceRates,
          } 
        })
      },
      (error) => {
        this.setState({
          isLoading: false,
          error: error,
        })
      }
    )
  }
}

export default OrderControl
