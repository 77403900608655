import React from 'react'
import { withRouter, Route, Switch, Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Container, Typography, Button, AppBar, Toolbar, IconButton, Box } from '@material-ui/core'
import { Menu } from '@material-ui/icons'

import AccountScreen from './account.screen'
import PaymentsScreen from './payments.screen'
import CardsScreen from './cards.screen'
import SideMenu from '../components/side-menu'
import ScooterControl from '../components/scooter/scooter-control'

import { Account } from '../lib/entities'
import { AccountKind } from '../lib/types'

interface IProps extends RouteComponentProps {
  account: Account
}
interface IState { }

class HomeScreen extends React.Component<IProps, IState> {
  sideMenuRef = React.createRef<SideMenu>()
  
  render() {
    let path = this.props.match.path
    return (
      <Container disableGutters>
        {this.renderUserBar()}
        <SideMenu ref={this.sideMenuRef} account={this.props.account} />
        <Switch>
          <Route path={`${path}/cards`}><CardsScreen /></Route>
          <Route path={`${path}/payments`}><PaymentsScreen /></Route>
          <Route path={`${path}/account`}><AccountScreen account={this.props.account} /></Route>
          <Route path={`${path}/qr/:deviceId`}><ScooterControl account={this.props.account} /></Route>
          <Route path={`${path}/`} exact>
            <Box mt={2}>
              <Typography variant='h6'>Считайте QR-код, чтобы покататься</Typography>
            </Box>
          </Route>
        </Switch>
      </Container>
    )
  }
  
  renderUserBar() {
    let path: string = this.props.match.url
    if (path.includes('/qr/')) {
      return ''
    }
    
    return (
      <AppBar position="static">
        <Toolbar>
          <Box display='flex' flexGrow={1}>
            <IconButton edge="start" color="inherit" onClick={this.openSideMenu.bind(this)}>
              <Menu />
            </IconButton>
          </Box>
          {
            this.props.account.kind === AccountKind.anonymous
              ? <Button component={Link} to='/auth/' color="inherit">Войти</Button>
              : <Typography variant="subtitle1">{this.props.account.login}</Typography>
          }
        </Toolbar>
      </AppBar>
    )
  }
  
  openSideMenu() {
    this.sideMenuRef.current?.open()
  }
}

export default withRouter(HomeScreen)
