const AUTH_TOKEN = 'atk'
const REFRESH_TOKEN = 'rtk'

export const LAST_VISITED_DEVICE_ID = 'lvdid'

export class State {
  authToken?: string
  refreshToken?: string
  
  load() {
    this.authToken = localStorage.getItem(AUTH_TOKEN) ?? undefined
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN) ?? undefined
    console.log('State load')
  }
  
  save() {
    if (this.authToken) {
      localStorage.setItem(AUTH_TOKEN, this.authToken)
    } else {
      localStorage.removeItem(AUTH_TOKEN)
    }
    
    if (this.refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, this.refreshToken)
    } else {
      localStorage.removeItem(REFRESH_TOKEN)
    }
  }
}

const state = new State()
state.load()

export default state
