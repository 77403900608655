import React from 'react'
import { Box, Typography, List, ListItem, ListItemText } from '@material-ui/core'

import { Card } from '../lib/entities'
import api from '../lib/api'

interface IProps { }
interface IState { 
  cards: Card[]
  isLoaded: boolean
  error: Error | null
}

class CardsScreen extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      cards: [],
      error: null,
    }
  }
  
  componentDidMount() {
    this.reloadPayments()
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (
        <Box mt={2}>
          <Typography variant='subtitle1'>Загружаем..</Typography>
        </Box>
      )
    }

    if (this.state.error) {
      return (
        <Box mt={2}>
          <Typography variant='subtitle1'>Ошибка {this.state.error.message}</Typography>
        </Box>    
      )
    }
    
    if (this.state.cards.length < 1) {
      return (
        <Box mt={2}>
          <Typography variant='h6'>Пока нет сохраненных карт</Typography>
          <Typography variant='subtitle1'>Покатайтесь на самокате, чтобы сохранить карту</Typography>
        </Box>
      )
    }
    
    return (
      <Box mt={2}>
        <Typography variant='h6'>Сохраненные карты</Typography>
        <List>
          {this.state.cards.map((card) => this.renderCard(card))}
        </List>
      </Box>
    )
  }
  
  renderCard(card: Card) {
    return (
      <ListItem>
        <ListItemText>* {card.lastFour} {card.isSandbox ? " (тестовая)" : ""}</ListItemText>
      </ListItem>
    )
  }
  
  reloadPayments() {
    api.cardsGet().then(
      (response) => {
        this.setState({
          isLoaded: true,
          error: null,
          cards: response,
        })
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error: error,
        })
      }
    )
  }
}

export default CardsScreen
