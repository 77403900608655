import React from 'react'
import { Typography } from '@material-ui/core'

import { getTime } from '../../lib/helpers'

interface IProps { 
  endTime: number
  timeDiff: number
}

interface IState { }

class TimeDisplay extends React.Component<IProps, IState> {
  tickTimer: NodeJS.Timeout | null = null
  
  componentDidMount() {
    if (this.tickTimer) {
      clearInterval(this.tickTimer)
    }
    this.tickTimer = setInterval(() => this.tick(), 1000)
  }
  
  componentWillUnmount() {
    if (this.tickTimer) {
      clearInterval(this.tickTimer)
      this.tickTimer = null
    }
  }
  
  render() {
    let timeRemaining = this.props.endTime - getTime() - this.props.timeDiff
    
    if (this.props.endTime < 1) {
      return ('')
    }
    
    if (timeRemaining < 0) {
      return (<Typography>Время истекло</Typography>)
    }
    
    return (<Typography>Остается времени: {this.formatTimeRemaining(timeRemaining)}</Typography>)
  }
  
  formatTimeRemaining(time: number): string {
    let seconds = Math.floor(time % 60)
    let minutes = Math.floor(time / 60 % 60)
    let hours = Math.floor(time / 60 / 60)
    
    return (hours > 0 ? hours + ":" : "" ) 
      + (minutes > 9 ? "" + minutes : "0" + minutes)
      + ":"
      + (seconds > 9 ? "" + seconds : "0" + seconds)
  }
  
  tick() {
    this.setState({})
  }
}

export default TimeDisplay
