import React from 'react'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import { Container, Box, Grid, Typography, Button, TextField } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

import { LoginMode } from '../../lib/types'

interface IProps { 
  onSubmit: (login: string, mode: LoginMode) => void
  phone: string | null
  email: string | null
  mode: LoginMode
}

interface IState { 
  phone: string
  email: string
  mode: LoginMode
}

class LoginForm extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    
    this.state = { 
      phone: props.phone ?? "", 
      email: props.email ?? "",
      mode: props.mode
    }
  }
  
  render() {
    return (
      <Container>
        <Box mt={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5">Войти используя</Typography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup exclusive onChange={this.handleModeChange.bind(this)} size='small'>
                {/* <ToggleButton value={LoginMode.phone} selected={this.state.mode === LoginMode.phone}>Телефон</ToggleButton> */}
                <ToggleButton value={LoginMode.email} selected={this.state.mode === LoginMode.email}>E-mail</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
            {
              this.state.mode === LoginMode.phone 
                ? this.renderPhone()
                : this.renderEmail()
            }
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleSubmit.bind(this)}>Войти</Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
  
  renderPhone() {
    return (
      <InputMask mask='+7 (999) 999-9999' maskPlaceholder='_' value={this.state.phone} onChange={this.handlePhoneChange.bind(this)}>
        <TextField label='Номер телефона' autoFocus variant='outlined' inputProps={{ inputMode: 'numeric' }} />
      </InputMask>
    )
  }
  
  renderEmail() {
    return (
      <TextField label='E-mail' autoFocus variant='outlined' inputProps={{ inputMode: 'email' }} value={this.state.email} onChange={this.handleEmailChange.bind(this)} />
    )
  }
  
  handleModeChange(_event: any, mode: LoginMode) {
    this.setState({mode: mode})
  }
  
  handlePhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({phone: e.target.value});
  }
  
  handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({email: e.target.value});
  }
  
  handleSubmit() {
    //FIXME: validate email
    
    switch (this.state.mode) {
      case LoginMode.phone:
        let phone = this.state.phone.replace(/\D/g,'')
        if (phone.length < 11) {
          toast.error('Пожалуйста, введите правильный номер телефона')
          return
        }
        
        this.props.onSubmit(phone, LoginMode.phone)
        break
      
      
      case LoginMode.email:
        this.props.onSubmit(this.state.email, LoginMode.email)
        break
    }
  }
}

export default LoginForm
