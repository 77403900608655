import React from 'react'
import { withRouter } from 'react-router-dom'
import { Switch, Route, RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'

import LoginForm from '../components/auth/LoginForm'
import PassForm from '../components/auth/PassForm'
import CodeForm from '../components/auth/CodeForm'

import { LoginMode, AuthResponse } from '../lib/types'
import api from '../lib/api'
import state from '../lib/state'

interface IProps extends RouteComponentProps { }
interface IState { 
    login: string
    loginMode: LoginMode
}

class AuthScreen extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    
    this.state = { 
      login: "", 
      loginMode: LoginMode.email,
    }
  }
  
  render() {
    let path = this.props.match.path
    return (
      <div>
        <Switch>
          <Route path={`${path}/pass`}>
            <PassForm login={this.state.login} mode={this.state.loginMode} onValidated={this.handleAuth.bind(this)} />
          </Route>
          <Route path={`${path}/code`}>
            <CodeForm login={this.state.login} mode={this.state.loginMode} onValidated={this.handleAuth.bind(this)} />
          </Route>
          <Route path={`${path}/`}>
            <LoginForm 
              phone={this.state.loginMode === LoginMode.phone ? this.state.login : null}
              email={this.state.loginMode === LoginMode.email ? this.state.login : null}
              mode={this.state.loginMode}
              onSubmit={this.handleCredential.bind(this)} 
            />
          </Route>
        </Switch>
      </div>
    )
  }
  
  handleCredential(login: string, mode: LoginMode) {
    api.authCheckLogin(login).then(
      (result) => {
        let isAccountRegistered: boolean = result.isRegistered
        
        this.setState({
          login: login,
          loginMode: mode,
        })
        
        if (isAccountRegistered) {
          this.props.history.push("./pass")
          return
        }
        
        this.props.history.push("./code")
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
  
  handleAuth(response: AuthResponse) {
    state.authToken = response.authToken
    state.refreshToken = response.refreshToken
    state.save()
    
    this.props.history.push('/')
  }
}

export default withRouter(AuthScreen)
