import React from 'react'
import { toast } from 'react-toastify'
import { Grid, Typography, Button } from '@material-ui/core'

import { Account, ControlInfo, isControlInfo, Branch } from '../../lib/entities'
import api from '../../lib/api'
import { getTime } from '../../lib/helpers'

import OrderButton from '../order-button'
import TimeDisplay from './TimeDisplay'

interface IProps { 
  deviceId: string
  account: Account
  batteryCharge: number
  branch: Branch
}

interface IState { 
  error: Error | null
  isLoaded: boolean,
  control: ControlInfo,
  serverTimeDiff: number
}

class Control extends React.Component<IProps, IState> {
  tickTimer: NodeJS.Timeout | null = null
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      error: null,
      isLoaded: false,
      serverTimeDiff: 0,
      control: {
        canControl: false,
        isDeviceOn: false,
        rideExpTime: 0,
        lockExpTime: 0
      }
    }
  }
  
  componentDidMount() {
    this.updateStatus()
    
    if (this.tickTimer) {
      clearInterval(this.tickTimer)
    }
    this.tickTimer = setInterval(() => this.tick(), 5000)
  }
  
  componentWillUnmount() {
    if (this.tickTimer) {
      clearInterval(this.tickTimer)
      this.tickTimer = null
    }
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<Typography>Загружаем..</Typography>)
    }
    
    if (this.state.error) {
      return (<Typography>Ошибка: {this.state.error.message}</Typography>)
    }
    
    if (!this.state.control.canControl) {
      return (<Typography>Самокат сейчас использует другой пользователь</Typography>)
    }
    
    if (!this.state.control.isDeviceOn) {
      return (<OrderButton deviceId={this.props.deviceId} branch={this.props.branch} account={this.props.account} onPurchase={this.handleOnPurchase.bind(this)} title="Поехали!" isExpanded={true} batteryCharge={this.props.batteryCharge} />)
    }
     
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TimeDisplay endTime={this.state.control.rideExpTime} timeDiff={this.state.serverTimeDiff} />
        </Grid>
        <Grid item xs={12}>
          <OrderButton deviceId={this.props.deviceId} branch={this.props.branch} account={this.props.account} onPurchase={this.handleOnPurchase.bind(this)} title="Докупить время" isExpanded={false} batteryCharge={this.props.batteryCharge} />
        </Grid>
        <Grid item xs={12}>
          <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleOpenLock.bind(this)}>Открыть замок</Button>
        </Grid>
        <Grid item xs={12}>
          <Button type='submit' value='off' size='large' variant='contained' color='primary' onClick={this.handleTogglePower.bind(this)}>Приостановить</Button>
        </Grid>
        <Grid item xs={12}>
          <Button type='submit' value='on' size='large' variant='contained' color='primary' onClick={this.handleTogglePower.bind(this)}>Продолжить</Button>
        </Grid>
        <Grid item xs={12}>
          <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleEndRide.bind(this)}>Завершить поедку</Button>
        </Grid>
      </Grid>
    )
  }
  
  tick() {
    this.updateStatus()
  }
  
  updateStatus() {
    let requestStartTime = getTime()
    api.deviceGetControl(this.props.deviceId).then(
      (result) => {
        if (!isControlInfo(result)) {
          this.setState({
            isLoaded: true,
            error: new Error('Malformed response')
          })
          return
        }
        
        let timeDiff = result.now - 0.5 * (requestStartTime + getTime())
        
        this.setState({
          isLoaded: true,
          control: result,
          serverTimeDiff: timeDiff,
          error: null
        })
      },
      (error) => {
        if (error.status === 401) {
          window.location.reload()
          return
        }
        
        this.setState({
          isLoaded: true,
          error: error
        })
      }
    )
  }
  
  handleOpenLock() {
    api.deviceOpenLock(this.props.deviceId).then(
      (_result) => {
        toast.success('Открываем..')
      },
      (error) => {
        toast.error(`Ошибка: ${error.message}`)
      }
    )
  }
  
  handleEndRide() {
    api.deviceEndRide(this.props.deviceId).then(
      (_result) => {
        toast.success('Поездка завершена')
        this.updateStatus()
      },
      (error) => {
        toast.error(`Ошибка: ${error.message}`)
      }
    )
  }
  
  handleOnPurchase() {
    this.setState({
      isLoaded: false
    })
    this.updateStatus()
    
    let text = this.props.branch.purchaseText
    if (text && text.length > 1) {
      toast.success(text)
    }
  }
  
  handleTogglePower(e: React.MouseEvent<HTMLButtonElement>) {
    let isOn: boolean = e.currentTarget.value === 'on'
    api.deviceTogglePower(isOn, this.props.deviceId).then(
      (_result) => {
        toast.success(isOn ? 'Продолжаем' : 'Приостановлено')
        this.updateStatus()
      },
      (error) => {
        toast.error(`Ошибка: ${error.message}`)
      }
    )
  }
}

export default Control
