import React from 'react'
import './App.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

import LandingScreen from './screens/landing.screen'
import AuthScreen from './screens/auth.screen'
import BigMapScreen from './screens/big-map.screen'
import LogOut from './components/auth/log-out'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4ebbc2',
      contrastText: '#ffffff',
    },
  },
})

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route path='/map'><BigMapScreen /></Route>
              <Route path="/auth"><AuthScreen /></Route>
              <Route path='/logout'><LogOut /></Route>
              <Route path="*"><LandingScreen /></Route>
            </Switch>
          </BrowserRouter>
          <ToastContainer />
        </ThemeProvider>
      </div>
    )
  }
}

export default App
