import React from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'
import { Container, Box, Grid, Typography, Button, TextField } from '@material-ui/core'

import { Account } from '../../lib/entities'
import api from '../../lib/api'

interface IProps extends RouteComponentProps {
  account: Account
  onPassChange: () => void
}
interface IState { 
  pass: string
  confirmPass: string
}

class SetPass extends React.Component<IProps, IState> {
  render() {
    return (
      <Container>
        <Box mt={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography color='textSecondary'>
                Смена пароля для: 
              </Typography>
              <Typography component="h1" variant="h5" color='textPrimary'>
                {this.props.account.login}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField type='password' label='Новый пароль' autoFocus variant='outlined' onChange={this.handlePassChange.bind(this)} />
            </Grid>
            <Grid item xs={12}>
              <TextField type='password' label='Еще раз' variant='outlined' onChange={this.handleConfirmPassChange.bind(this)} />
            </Grid>
            <Grid item xs={12}>
              <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleSubmit.bind(this)}>Сменить пароль</Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
  
  handlePassChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({pass: e.target.value});
  }
  
  handleConfirmPassChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({confirmPass: e.target.value});
  }
  
  handleSubmit() {
    if (this.state.pass.length < 2) {
      toast.error('Пароль должен быть длинее 2х символов')
      return
    }
    
    if (this.state.pass !== this.state.confirmPass) {
      toast.error('Пароль отличается от подтверждения')
      return
    }
    
    api.authUpdatePassword(this.state.pass).then(
      (_result) => {
        this.props.onPassChange()
      },
      (error) => {
        toast.error(error.message)
      }
    )
  }
}

export default withRouter(SetPass)
