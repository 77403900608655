import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Box, Grid, Typography, Button } from '@material-ui/core'
import { toast } from 'react-toastify'

import Info from './Info'
import Control from './control'
import OrderButton from '../order-button'
import { Account, Branch } from '../../lib/entities'
import { AccountKind, BranchKind } from '../../lib/types'
import config from '../../lib/config'
import api from '../../lib/api'
import { LAST_VISITED_DEVICE_ID } from '../../lib/state'

interface DeviceIdMatchParams {
  deviceId: string
}

interface IProps extends RouteComponentProps<DeviceIdMatchParams> {
  account: Account
}

interface IState {
  batteryCharge: number
  branch?: Branch
  isLoading: boolean
  error?: Error
}

class ScooterContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      batteryCharge: 0,
      isLoading: true,
    }
  }
  
  componentDidMount() {
    this.reloadBranch()
    
    localStorage.setItem(LAST_VISITED_DEVICE_ID, this.props.match.params.deviceId)
    console.log('set - ' + this.props.match.params.deviceId)
  }
  
  render() {
    if (this.state.isLoading) {
      return (<Container><Box mt={4}>Загружаем..</Box></Container>)
    }
    
    if (!this.state.branch) {
      return (<Container><Box mt={4}>Ошибка: {this.state.error?.message ?? 'что-то пошло не так'}</Box></Container>)
    }
    
    if (this.state.branch.kind === BranchKind.milk) {
      return (
        <Container>
          <Box mt={4}>
            <Grid container spacing={2}>
              { this.renderAdminIfAble() }
              <Grid item xs={12}>
                <Typography>{this.state.branch?.warningText}</Typography>
              </Grid>
              <Grid item xs={12}>
                <OrderButton deviceId={this.props.match.params.deviceId} account={this.props.account} branch={this.state.branch} onPurchase={this.handleOnPurchase.bind(this)} isExpanded={true} batteryCharge={100} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      )
    }
    
    return (
      <Container>
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Самокат {this.props.match.params.deviceId}
              </Typography>
            </Grid>
            { this.renderAdminIfAble() }
            <Grid item xs={12}>
              <Info deviceId={this.props.match.params.deviceId} onBatteryChange={this.handleBatteryChange.bind(this)} />
            </Grid>
            <Grid item xs={12}>
              <Typography>{this.state.branch?.warningText}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Control deviceId={this.props.match.params.deviceId} account={this.props.account} batteryCharge={this.state.batteryCharge} branch={this.state.branch} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }
  
  renderAdminIfAble() {
    switch (this.props.account.kind) {
      case AccountKind.admin:
      case AccountKind.superadmin:
        return (
          <Grid item xs={12}>
            <Button type='submit' size='large' variant='contained' color='primary' onClick={this.handleAdminTap.bind(this)}>Я - админ</Button>
          </Grid>
        )
      
      default:
        return ""
    }
  }
  
  reloadBranch() {
    api.branchGetByDevice(this.props.match.params.deviceId).then(
      (result) => {
        this.setState({
          isLoading: false,
          branch: result,
        })
      },
      (error) => { 
        this.setState({
          isLoading: false,
          error,
        })
      }
    )
  }
  
  handleAdminTap() {
    let url = config.adminUrl + 'device/' + this.props.match.params.deviceId
    window.location.assign(url)
  }
  
  handleBatteryChange(batteryCharge: number) {
    this.setState({
      batteryCharge: batteryCharge
    })
  }
  
  handleOnPurchase() {
    let text = this.state.branch?.purchaseText
    if (text && text.length > 1) {
      toast.success(text)
    }
  }
}

export default withRouter(ScooterContainer)
