
export enum LoginMode {
  phone, email
}

export enum AccountKind {
  anonymous = 'anonymous',
  registered = 'registered',
  admin = 'admin',
  superadmin = 'superadmin',
}

export interface MapPoint {
  x: number
  y: number
}

export interface PriceRate {
  id: number,
  price: number,
  minutes: number,
  minBattery: number,
}

export interface AuthResponse {
  authToken: string,
  refreshToken: string,
}

export enum BranchKind {
  scooters = 'scooters',
  milk = 'milk',
}
