import React from 'react'
import { withRouter, Redirect, Switch, Route } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { toast } from 'react-toastify'
import { Typography, Box } from '@material-ui/core'

import HomeScreen from './home.screen'
import SetPassScreen from '../components/account/set-pass'
import LogOut from '../components/auth/log-out'

import state, { LAST_VISITED_DEVICE_ID } from '../lib/state'
import api from '../lib/api'
import { Account } from '../lib/entities'
import { AccountKind } from '../lib/types'
import { validTimeRemainingFor } from '../lib/utils/jwt'
import config from '../lib/config'

interface IProps extends RouteComponentProps { }
interface IState { 
  isLoaded: boolean
  account?: Account
}

class LandingScreen extends React.Component<IProps, IState> {
  refreshCheckTimer?: NodeJS.Timeout
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      isLoaded: false,
      account: undefined,
    }
  }
  
  componentDidMount() {
    if (this.refreshCheckTimer) {
      clearInterval(this.refreshCheckTimer)
    }
    this.refreshCheckTimer = setInterval(() => this.checkRefreshToken(), 60*1000)//once a minute
    
    this.reloadAppState()
  }
  
  componentWillUnmount() {
    if (this.refreshCheckTimer) {
      clearInterval(this.refreshCheckTimer)
      this.refreshCheckTimer = undefined
    }
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<Box mt={4}><Typography>Загружаем..</Typography></Box>)
    }
    
    if (!this.state.account) {
      return (<Redirect to='/auth/' />)
    }
    
    if (!this.state.account.isRegistered && this.state.account.kind !== AccountKind.anonymous) {
      return (
        <Box mt={2}>
          <SetPassScreen account={this.state.account} onPassChange={this.handlePasswordChange.bind(this)} />
        </Box>
      )
    }
    
    return (
      <Box>
        <Switch>
          <Route path='/logout'>
            <LogOut />
          </Route>
          <Route path='*'>
            <HomeScreen account={this.state.account} />
          </Route>
        </Switch>
      </Box>
    )
  }
  
  reloadAppState() {
    let refreshTimeRemaining = validTimeRemainingFor(state.refreshToken)
    if (refreshTimeRemaining < 0) {
      this.reloadAppAsAnon()
      return
    }
    
    let authTimeRemaining = validTimeRemainingFor(state.authToken)
    if (authTimeRemaining < config.authTokenRefreshTimeBefore) {
      api.refreshToken = state.refreshToken
      this.refreshAuth(() => this.reloadUser())
      return
    }
    
    api.refreshToken = state.refreshToken
    api.authToken = state.authToken
    
    this.reloadUser()
  }
  
  refreshAuth(onSuccess: () => void) {
    api.authRefresh().then(
      (result) => {
        let authToken = result.authToken
        api.authToken = authToken
        state.authToken = authToken
        state.save()
        
        onSuccess()
      },
      (error) => {
        if (error.status === 401) {
          api.authToken = undefined
          api.refreshToken = undefined
          state.authToken = undefined
          state.refreshToken = undefined
          state.save()
        } else {
          toast.error(error.message)
        }
        
        this.reloadAppAsAnon()
      }
    )
  }
  
  reloadAppAsAnon() {
    api.refreshToken = undefined
    api.authToken = state.authToken
    
    let authTimeRemaining = validTimeRemainingFor(state.authToken)
    if (authTimeRemaining < config.authTokenRefreshTimeBefore) {
      this.refreshAnonymousAuth(() => this.reloadUser())
      return
    }
    
    this.reloadUser()
  }
  
  authAsAnonymous(onSuccess: () => void) {
    api.authAsAnonymous().then(
      (result) => {
        let token = result.authToken
        api.authToken = token
        api.refreshToken = undefined
        state.authToken = token
        state.refreshToken = undefined
        state.save()
        
        onSuccess()
      },
      (error) => {
        toast.error(error.message)
        this.setState({
          account: undefined,
          isLoaded: true,
        })
      }
    )
  }
  
  refreshAnonymousAuth(onSuccess: () => void) {
    api.authRefreshAnonymous().then(
      (result) => {
        let token = result.authToken
        api.authToken = token
        api.refreshToken = undefined
        state.authToken = token
        state.refreshToken = undefined
        state.save()
        
        onSuccess()
      },
      (_error) => {
        this.authAsAnonymous(onSuccess)
      }
    )
  }
  
  //tinkoff redirect url looks like http://localhost:3000/return/fail?Success=false&ErrorCode=1051&Message=%D0%9D%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D1%82%D0%BE%D1%87%D0%BD%D0%BE+%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2+%D0%BD%D0%B0+%D0%BA%D0%B0%D1%80%D1%82%D0%B5.&Details=&Amount=10000&MerchantEmail=RN21%40mail.ru&MerchantName=Tenzio.ru&OrderId=8000a542-b6b2-4c3b-8b11-109d81f421b8&PaymentId=1516933730&TranDate=&BackUrl=http%3A%2F%2Ftenzio.ru&CompanyName=%D0%98%D0%9F+%D0%93%D0%9E%D0%9B%D0%9E%D0%A4%D0%95%D0%95%D0%92+%D0%9B%D0%95%D0%9E%D0%9D%D0%98%D0%94+%D0%90%D0%9D%D0%90%D0%A2%D0%9E%D0%9B%D0%AC%D0%95%D0%92%D0%98%D0%A7&EmailReq=RN21%40mail.ru&PhonesReq=9153831433
  
  reloadUser() {
    let currentUrl = this.props.match.url
    if (currentUrl.startsWith('/return/')) {
      let oldDeviceId = localStorage.getItem(LAST_VISITED_DEVICE_ID)
      if (oldDeviceId) {
        let params = new URLSearchParams(this.props.location.search)
        
        if (params.get('Success') == 'true') {
          toast.success('Готово')
        } else {
          let message = params.get('Message')
          toast.error(message ?? 'Что-то пошло не так')
        }
        
        this.props.history.replace('/qr/' + oldDeviceId)
      } else {
        this.props.history.replace('/')
      }
    }
    
    this.setState({
      isLoaded: false
    })
    
    api.accountGetMy().then(
      (result) => {
        this.setState({
          isLoaded: true,
          account: result
        })
      },
      (error) => {
        if (error.status === 401) {
          api.authToken = undefined
          state.authToken = undefined
          state.save()
        } else {
          toast.error(error.message)
        }
        
        if (currentUrl.startsWith('/qr/')) {
          if (!api.refreshToken) {
            setTimeout(this.reloadAppAsAnon.bind(this), 5 * 1000)
            return
          }
          
          this.reloadAppAsAnon()
          return
        }
        
        this.setState({
          isLoaded: true,
          account: undefined,
        })
      }
    )
  }
  
  checkRefreshToken() {
    let authTimeRemaining = validTimeRemainingFor(state.authToken)
    if (authTimeRemaining < config.authTokenRefreshTimeBefore) {
      if (state.refreshToken) {
        this.refreshAuth(() => {})
      } else {
        this.refreshAnonymousAuth(() => {})
      }
    }
  }
  
  handlePasswordChange() {
    this.props.history.push('/')
    this.reloadUser()
  }
}

export default withRouter(LandingScreen)
