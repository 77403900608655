import React from 'react'
import { Typography } from '@material-ui/core'

import api from '../../lib/api'
import { DeviceInfo } from '../../lib/entities'

interface IProps { 
  deviceId: string
  onBatteryChange: (batteryCharge: number) => void
}

interface IState { 
  error: Error | null
  isLoaded: boolean
  info: DeviceInfo
}

class Info extends React.Component<IProps, IState> {
  tickTimer: NodeJS.Timeout | null = null
  
  constructor(props: IProps) {
    super(props)
    
    this.state = {
      error: null,
      isLoaded: false,
      info: {
        battery: 0
      }
    }
  }
  
  componentDidMount() {
    this.reloadData()
    
    if (this.tickTimer) {
      clearInterval(this.tickTimer)
    }
    this.tickTimer = setInterval(() => this.tick(), 20000)
  }
  
  componentWillUnmount() {
    if (this.tickTimer) {
      clearInterval(this.tickTimer)
      this.tickTimer = null
    }
  }
  
  render() {
    if (!this.state.isLoaded) {
      return (<Typography>Загружаем..</Typography>)
    }
    
    if (this.state.error) {
      return (<Typography>Ошибка: {this.state.error.message}</Typography>)
    }
    
    return (<Typography>Заряд батареи: {this.state.info.battery}%</Typography>)
  }
  
  tick() {
    this.reloadData()
  }
  
  reloadData() {
    api.deviceGetState(this.props.deviceId).then(
      (result) => {
        this.setState({
          isLoaded: true,
          info: result,
          error: null
        })
        this.props.onBatteryChange(result.battery)
      },
      (error) => {
        if (error.status === 401) {
          window.location.reload()
          return
        }
        
        this.setState({isLoaded: true, error})
      }
    )
  }
}

export default Info
