import React from 'react'
import { Redirect } from 'react-router-dom'

import state from '../../lib/state'

interface IProps { }
interface IState { }

class LogOut extends React.Component<IProps, IState> {
  componentDidMount() {
    state.refreshToken = undefined
    state.authToken = undefined
    state.save()
    
    window.location.reload()
  }
  
  render() {
    return <Redirect to='/' />
  }
}

export default LogOut
